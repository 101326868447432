/**
 * Created by carlosyanes on 5/10/16.
 */



//data


$(document).ready(function(){

    $('.toggle-menu').on('click', function(){
        $('.menu-mobile').addClass('show-c');
    });

    $('.close-c').on('click', function(){
        $('.menu-mobile').removeClass('show-c');
    });

});

$(window).on('load', function () {
    $(".pre-load").removeClass('pre-load');
});

$(window).resize(function(){

});


